/* global console, require, fetch, OfficeRuntime */
const Moment = require("moment-mini");
const version = "V1.3.6";
console.log(version);

// main request
async function track(tracking_number, courier_code, fields) {
  if (!tracking_number.length || !courier_code.length) throw new Error("Invalid request parameter");
  try {
    const apiKey = await getItem("key");
    if (!apiKey) throw new Error("Please login");

    const url = "https://api2.globalpackagetracker.com/sheets/shipment/track";
    const body = {
      tracking_number,
      courier_code,
      fields,
      source: `Excel ${version}`,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: apiKey,
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    if (data.status == 200 && data.shipment) return data.shipment;
    if (data.status == 200) {
      throw new Error("No shipment data returned");
    } else if (data.message) {
      throw new Error(data.message);
    } else {
      throw new Error("Unknown error while fetching");
    }
  } catch (error) {
    if (error.message === "Shipment already exists") throw new Error("Please try again");
    if (error.message === "No capacity. Please upgrade your plan.") throw new Error("You've reached your (free) capacity limit for tracking shipments. To continue, please purchase additional capacity at https://billing.globalpackagetracker.com/.");
    throw error;
  }
}

/**
 * Get the delivery status of the package
 * @customfunction
 * @param {string} tracking_number Provide your tracking number
 * @param {string} courier_code Provide the courier code
 * @return {string} Current delivery status of your package
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getStatus(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'delivery_status');
    if (!shipment.delivery_status) throw new Error("Delivery status not found. Please contact support.");
    return shipment.delivery_status;
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get current location and delivery status
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getCompleteStatus(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'delivery_status travel_history');
    if (shipment.travel_history && shipment.travel_history.length && shipment.travel_history[0].location) {
      return shipment.delivery_status + " at " + shipment.travel_history[0].location;
    } else {
      return shipment.delivery_status;
    }
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get current location of the package
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @helpurl https://globalpackagetracker.com/pages/contact
 * @return {string}
 */
export async function getCurrentLocation(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'travel_history');
    if (shipment.travel_history && shipment.travel_history.length && shipment.travel_history[0].location) {
      return shipment.travel_history[0].location;
    }
    return "Unknown";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get all the locations the package has traveled from first until last event
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getTraveledLocations(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'travel_history');
    const locations = [];
    if (!shipment.travel_history || !shipment.travel_history.length) return "No travel locations found";
    shipment.travel_history.forEach((event) => {
      if (!event.location) return;
      locations.push(event.location);
    });
    const uniqueLocations = [...new Set(locations)];
    if (!uniqueLocations.length) return "No travel locations found";
    return uniqueLocations.reverse().join(" -> ");
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get date from first event
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getShippingDate(tracking_number, courier_code) {
  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'shipping_date');
    if (shipment.shipping_date) {
      const date = new Date(shipment.shipping_date);
      return Moment(date).format(dateFormat);
    }
    return "Shipping date not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get label creation date
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getCreationDate(tracking_number, courier_code) {
  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'label_created_date');
    if (shipment.label_created_date) {
      const date = new Date(shipment.label_created_date);
      return Moment(date).format(dateFormat);
    }
    return "Labael creation date not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get tracking registration date
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getRegistrationDate(tracking_number, courier_code) {
  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'createdAt');
    if (shipment.createdAt) {
      const date = new Date(shipment.createdAt);
      return Moment(date).format(dateFormat);
    }
    return "Labael creation date not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get delivery date
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getDeliveryDate(tracking_number, courier_code) {
  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'delivery_date');
    if (shipment.delivery_date) {
      const date = new Date(shipment.delivery_date);
      return Moment(date).format(dateFormat);
    }
    return "Delivery date not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get origin of the package
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getOrigin(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'origin');
    if (shipment.origin) return shipment.origin;
    return "Shipment origin not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get destination of the package
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getDestination(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'destination');
    if (shipment.destination) return shipment.destination;
    return "Shipment destination not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get days passed from first until latest event
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getTransitTime(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'transit_time');
    if (shipment.transit_time) return shipment.transit_time;
    return "Transit time not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get costs
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getCosts(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'costs');
    if (shipment.costs) return shipment.costs;
    return "Costs need to be provided in our WebApp";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get service type
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getServiceType(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'service_type');
    if (shipment.service_type) return shipment.service_type;
    return "Unknown service type";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get latest event
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getLatestEvent(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'latest_event');
    if (shipment.latest_event) return shipment.latest_event;
    return "Latest event not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get latest event date
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getLatestEventDate(tracking_number, courier_code) {
  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'latest_event_time');
    if (shipment.latest_event_time) {
      const date = new Date(shipment.latest_event_time);
      return Moment(date).format(dateFormat);
    }
    return "Latest event time not found";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get notes
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getNotes(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'notes');
    console.log('Shipment notes', shipment.notes);
    if (shipment.notes) return shipment.notes;
    return "";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get details
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getDetails(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'more_details');
    if (shipment.more_details) {
      let details = shipment.more_details;
      details = details.replace(/\n/g, '. ');
      return details;
    }
    return "";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get additional fields
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @param {number} index
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getAdditionalField(tracking_number, courier_code, index) {
  console.log('Called additional field with following parameters', tracking_number, courier_code, index);
  try {
    const shipment = await track(tracking_number, courier_code, 'additional_field0 additional_field1 additional_field2');
    if (index === 1) return shipment.additional_field0 || "No data";
    if (index === 2) return shipment.additional_field1 || "No data";
    if (index === 3) return shipment.additional_field2 || "No data";
    return "No data";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get estimated delivery date
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getEstimatedDeliveryDate(tracking_number, courier_code) {
  let from
  let to

  try {
    var dateFormat = await getItem("date_format");
    if (!dateFormat) dateFormat = "YYYY-MM-DD";
    const shipment = await track(tracking_number, courier_code, 'time_metrics delivery_status');
    if (shipment.delivery_status && shipment.delivery_status === "Delivered") return "Already delivered"
    if (shipment.time_metrics) {
      if (shipment.time_metrics.estimated_delivery_date) {
        from = shipment.time_metrics.estimated_delivery_date.from
        to = shipment.time_metrics.estimated_delivery_date.to

        if (from && !to) {
          const dateWithoutOffset = from.includes('+') ? from.split('+')[0] : from
          const date = new Date(dateWithoutOffset);
          return Moment.utc(date).format(dateFormat);
        }

        if (!from && to) {
          const dateWithoutOffset = to.includes('+') ? to.split('+')[0] : to
          const date = new Date(dateWithoutOffset);
          return Moment.utc(date).format(dateFormat);
        }

        if (from && to) {
          const dateWithoutOffsetFrom = from.includes('+') ? from.split('+')[0] : from
          const dateWithoutOffsetTo = to.includes('+') ? to.split('+')[0] : to

          const dateFrom = new Date(dateWithoutOffsetFrom);
          const dateTo = new Date(dateWithoutOffsetTo);

          const dateFromString = Moment.utc(dateFrom).format(dateFormat)
          const dateToString = Moment.utc(dateTo).format(dateFormat)

          if (dateFromString === dateToString) return dateFromString

          return dateFromString + ' - ' + dateToString
        }
      }
    }
    return "Estimated delivery date not available";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get weight
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getWeight(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'weight');
    if (shipment.weight) {
      var weight_unit = await getItem("weight_unit");
      if (weight_unit === "lb") {
        return convertKgToLb(shipment.weight);
      }
      return shipment.weight;
    }
    return "Shipment weight can be provided in our WebApp or you can use getWeightFromCourier function";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get weight provided by the carrier
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getWeightFromCourier(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'weight_courier_kg weight_courier_lb weight_courier_raw');
    if (shipment.weight_courier_kg || shipment.weight_courier_lb) {
      var weight_unit = await getItem("weight_unit");
      if (weight_unit === "lb" && shipment.weight_courier_lb) return shipment.weight_courier_lb;
      if (weight_unit === "lb" && shipment.weight_courier_kg) return convertKgToLb(shipment.weight_courier_kg);
      if (weight_unit === "kg" && shipment.weight_courier_kg) return shipment.weight_courier_kg;
      if (weight_unit === "kg" && shipment.weight_courier_lb) return convertLbToKg(shipment.weight_courier_kg);
    }
    if (shipment.weight_courier_raw) return shipment.weight_courier_raw;
    return "Weight not provided by courier";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get customer
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getCustomer(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'customer');
    if (shipment.customer) return shipment.customer.customer_name;
    return "Customers can be assigned in our WebApp";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get shipped by
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getShippedBy(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'shippedBy');
    if (shipment.shippedBy) return shipment.shippedBy.hub_name;
    return "Hubs can be assigned in our WebApp";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}

/**
 * Get shipped to
 * @customfunction
 * @param {string} tracking_number
 * @param {string} courier_code
 * @return {string}
 * @helpurl https://globalpackagetracker.com/pages/contact
 */
export async function getShippedTo(tracking_number, courier_code) {
  try {
    const shipment = await track(tracking_number, courier_code, 'shippedTo');
    if (shipment.shippedTo) return shipment.shippedTo.hub_name;
    return "Hubs can be assigned in our WebApp";
  } catch (error) {
    console.log(error);
    return error.toString();
  }
}


// /**
//  * Get product
//  * @customfunction
//  * @param {string} tracking_number
//  * @param {string} courier_code
//  * @return {string}
//  * @helpurl https://globalpackagetracker.com/pages/contact
//  */
// export async function getProduct(tracking_number, courier_code) {
//   try {
//     const shipment = await track(tracking_number, courier_code);
//     if (shipment.product) return shipment.product.product_name;
//     return "No product assigned yet";
//   } catch (error) {
//     console.log(error);
//     return error.toString();
//   }
// }


// helper functions
function convertKgToLb(weight) {
  return (weight * 2.205).toFixed(2);
}
function convertLbToKg(weight) {
  return (weight / 2.205).toFixed(2);
}

async function getItem(key) {
  try {
    const value = await OfficeRuntime.storage.getItem(key);
    return value;
  } catch (error) {
    console.log("Error retrieving data", error);
    return false;
  }
}

CustomFunctions.associate("GETSTATUS", getStatus);
CustomFunctions.associate("GETCOMPLETESTATUS", getCompleteStatus);
CustomFunctions.associate("GETCURRENTLOCATION", getCurrentLocation);
CustomFunctions.associate("GETTRAVELEDLOCATIONS", getTraveledLocations);
CustomFunctions.associate("GETSHIPPINGDATE", getShippingDate);
CustomFunctions.associate("GETCREATIONDATE", getCreationDate);
CustomFunctions.associate("GETREGISTRATIONDATE", getRegistrationDate);
CustomFunctions.associate("GETDELIVERYDATE", getDeliveryDate);
CustomFunctions.associate("GETORIGIN", getOrigin);
CustomFunctions.associate("GETDESTINATION", getDestination);
CustomFunctions.associate("GETTRANSITTIME", getTransitTime);
CustomFunctions.associate("GETCOSTS", getCosts);
CustomFunctions.associate("GETSERVICETYPE", getServiceType);
CustomFunctions.associate("GETLATESTEVENT", getLatestEvent);
CustomFunctions.associate("GETLATESTEVENTDATE", getLatestEventDate);
CustomFunctions.associate("GETNOTES", getNotes);
CustomFunctions.associate("GETDETAILS", getDetails);
CustomFunctions.associate("GETADDITIONALFIELD", getAdditionalField);
CustomFunctions.associate("GETESTIMATEDDELIVERYDATE", getEstimatedDeliveryDate);
CustomFunctions.associate("GETWEIGHT", getWeight);
CustomFunctions.associate("GETWEIGHTFROMCOURIER", getWeightFromCourier);
CustomFunctions.associate("GETCUSTOMER", getCustomer);
CustomFunctions.associate("GETSHIPPEDBY", getShippedBy);
CustomFunctions.associate("GETSHIPPEDTO", getShippedTo);